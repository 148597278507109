(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:InscribedTeams
   *
   * @description
   *
   */
  angular
      .module('neo.services')
      .factory('InscribedTeams', InscribedTeams);

  function InscribedTeams($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/phases/:phaseId/inscriptions', {phaseId: '@_phaseId'});
  }
}());
